import BaseState from "../../interfaces/BaseState";

export type FetchDPAContractsSuccessPayload = DPAContracts;
export type DPAContractsByCompanyId = Record<number, DPAContract[]>;
export type SignLinkRequestSentByContractId = Record<number, boolean>;

export type DPAContract = {
    id: number;
    createdAt: string;
    updatedAt: string;
    providerId: string;
    providerName: string;
    providerContractId: string;
    state: ContractState;
};

export enum ContractState {
    UNKNOWN,
    PENDING,
    CANCELLED,
    ACTIVE,
}

export type DPAContracts = {
    userDpaContracts: DPAContract[];
    companyDpaContractsByCompanyId: DPAContractsByCompanyId;
};

export interface DPAContractState extends BaseState {
    userDpaContracts: DPAContract[];
    companyDpaContractsByCompanyId: DPAContractsByCompanyId;
    inviteeSignLinkSentByContractId: SignLinkRequestSentByContractId;
}

export interface SignLinkRequestPayload {
    contractId: number;
}

export interface SetSignLinkRequestSentByContractIdPayload {
    contractId: number;
}

export interface SignLinkRequestSuccessPayload {
    contractId: number;
}
