import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../interfaces/RootState";

const Spinner = () => {
    const authLoading = useSelector((state: RootState) => state.hydrolink.auth.loading);
    const apartmentsLoading = useSelector((state: RootState) => state.hydrolink.apartments.loading);
    const companiesLoading = useSelector((state: RootState) => state.hydrolink.companies.loading);
    const usersLoading = useSelector((state: RootState) => state.hydrolink.users.loading);
    const residentsLoading = useSelector((state: RootState) => state.hydrolink.residents.loading);
    const notesLoading = useSelector((state: RootState) => state.hydrolink.notes.loading);
    const centersLoading = useSelector((state: RootState) => state.hydrolink.centers.loading);
    const reportsLoading = useSelector((state: RootState) => state.hydrolink.reports.loading);
    const DpaContractsLoading = useSelector((state: RootState) => state.hydrolink.dpaContracts.loading);

    const loading = (): boolean =>
        authLoading > 0 ||
        apartmentsLoading > 0 ||
        companiesLoading > 0 ||
        usersLoading > 0 ||
        residentsLoading > 0 ||
        notesLoading > 0 ||
        centersLoading > 0 ||
        reportsLoading > 0 ||
        DpaContractsLoading > 0;

    return (
        <Backdrop sx={{ backgroundColor: "rgba(255, 255, 255, .5)" }} open={loading()}>
            <CircularProgress color="primary"></CircularProgress>
        </Backdrop>
    );
};

export default Spinner;
