import { AnyAction } from "redux";
import { DPAContractState } from "./dpa-contracts-types";
import {
    FetchDPAContractsAction,
    FetchDPAContractsFailureAction,
    FetchDPAContractsSuccessAction,
    SetInviteeSignLinkSentByContractIdAction,
    SendInviteeSignLinkAction,
    SendInviteeSignLinkFailureAction,
    SendInviteeSignLinkSuccessAction,
} from "./dpa-contracts-actions";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";

const initialState: DPAContractState = {
    userDpaContracts: [],
    companyDpaContractsByCompanyId: {},
    loading: 0,
    inviteeSignLinkSentByContractId: {},
};

const actions = (state: DPAContractState, action: AnyAction) => {
    if (FetchDPAContractsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SendInviteeSignLinkAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SetInviteeSignLinkSentByContractIdAction.match(action)) {
        return {
            ...state,
            inviteeSignLinkSentByContractId: {
                ...state.inviteeSignLinkSentByContractId,
                [action.payload.contractId]: false,
            },
        };
    }

    return state;
};

const successActions = (state: DPAContractState, action: AnyAction) => {
    if (FetchDPAContractsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            userDpaContracts: action.payload.userDpaContracts,
            companyDpaContractsByCompanyId: action.payload.companyDpaContractsByCompanyId,
        };
    }

    if (SendInviteeSignLinkSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            inviteeSignLinkSentByContractId: {
                ...state.inviteeSignLinkSentByContractId,
                [action.payload.contractId]: true,
            },
        };
    }
    return state;
};

const failureActions = (state: DPAContractState, action: AnyAction) => {
    if (FetchDPAContractsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SendInviteeSignLinkFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

export default function dpaContractReducer(
    state: DPAContractState = initialState,
    action: AnyAction,
): DPAContractState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);

    return state;
}
