import { Company, Role } from "../redux/companies/companies-types";
import { useSelector } from "react-redux";
import { RootState } from "../interfaces/RootState";
import { useTranslation } from "react-i18next";
import { CompanyRole } from "../redux/auth/auth-types";

export type Privilege =
    | "can rename apartment"
    | "can see api settings"
    | "can edit company invoice settings"
    | "can see company invoice settings"
    | "can remove manager"
    | "can set principal manager"
    | "is allowed to add users to the company"
    | "can see all users"
    | "can change company user role"
    | "can see create user button in the managers block"
    | "as a manager can add company user"
    | "as an admin can add company user"
    | "can see center code row"
    | "can toggle api access"
    | "can update basic company info"
    | "can upload apartments from csv"
    | "can upload & download residents"
    | "can see admin alarms"
    | "can toggle premium"
    | "can see a company without DPA requirements"
    | "can delete company"
    | "can see admin company tabs"
    | "can see admin hamburger menu tabs"
    | "can update alarm settings"
    | "can delete apartment"
    | "can refresh api key"
    | "can merge apartments"
    | "can save premium settings"
    | "can save report settings"
    | "can update client telephone number"
    | "can reset Hydrodigit LR Alarms"
    | "can delete meter"
    | "can open meter properties"
    | "can send test reports"
    | "can see premium settings"
    | "can see residents"
    | "can set premium alarm settings"
    | "can see and download invoice reports"
    | "can mark alarm date changes"
    | "can see center id and version"
    | "can access dpa contracts management"
    | "can manage dpa contracts of this company"
    | "can see premium alarms";

export type PrivilegeConditions = {
    roles: Role[];
    premiumNeeded?: boolean;
    principalNeeded?: boolean;
    isPrincipalInSomeCompany?: boolean;
};

export type PrivilegeRoles = Record<Privilege, PrivilegeConditions>;

const manager: PrivilegeConditions = {
    roles: ["admin", "manager"],
    premiumNeeded: false,
};

const principalManager: PrivilegeConditions = {
    roles: ["admin", "manager"],
    premiumNeeded: false,
    principalNeeded: true,
};

const isPrincipalInSomeCompany: PrivilegeConditions = {
    roles: ["admin", "manager", "viewer", "no role"],
    premiumNeeded: false,
    isPrincipalInSomeCompany: true,
};

const admin: PrivilegeConditions = {
    roles: ["admin"],
    premiumNeeded: false,
};

const managerWithPremium: PrivilegeConditions = {
    roles: ["admin", "manager"],
    premiumNeeded: true,
};

const managerOnly: PrivilegeConditions = {
    roles: ["manager"],
    premiumNeeded: false,
};

const allWithPremium: PrivilegeConditions = {
    roles: ["admin", "manager", "viewer"],
    premiumNeeded: true,
};

const privilegeStore: PrivilegeRoles = {
    "can rename apartment": manager,
    "can see api settings": manager,
    "can edit company invoice settings": manager,
    "can see company invoice settings": manager, //TODO: make sure this is correct or should viewer see them too?
    "can remove manager": manager,
    "can set principal manager": admin,
    "is allowed to add users to the company": manager,
    "can see all users": admin,
    "can change company user role": admin,
    "can see create user button in the managers block": admin,
    "as a manager can add company user": managerOnly,
    "as an admin can add company user": admin,
    "can see center code row": manager, //Not sure if this should include manager at all since the manager cannot even see the centers tab?
    "can toggle api access": admin,
    "can update basic company info": admin,
    "can upload apartments from csv": admin,
    "can upload & download residents": managerOnly,
    "can see admin alarms": admin,
    "can toggle premium": admin,
    "can see a company without DPA requirements": admin,
    "can delete company": admin,
    "can see admin company tabs": admin,
    "can see admin hamburger menu tabs": admin,
    "can update alarm settings": manager,
    "can delete apartment": admin,
    "can refresh api key": admin,
    "can merge apartments": admin,
    "can save premium settings": manager,
    "can save report settings": manager,
    "can update client telephone number": manager,
    "can reset Hydrodigit LR Alarms": manager,
    "can delete meter": admin,
    "can open meter properties": managerWithPremium,
    "can send test reports": admin,
    "can see premium settings": allWithPremium,
    "can see residents": managerWithPremium,
    "can set premium alarm settings": managerWithPremium,
    "can see and download invoice reports": managerWithPremium,
    "can mark alarm date changes": admin,
    "can see center id and version": admin,
    "can access dpa contracts management": isPrincipalInSomeCompany,
    "can manage dpa contracts of this company": principalManager,
    "can see premium alarms": allWithPremium,
};

type Result = {
    hasPrivilege: boolean;
    missingRequirement?: string;
};

function myCompanyRoleByCompanyId(roleByCompanyId: Record<number, CompanyRole>, companyId?: number): CompanyRole {
    const defaultCompanyRole: CompanyRole = { role: "no role", principal: false, dpaRequirementsMet: true };
    if (companyId === undefined) return defaultCompanyRole;
    return roleByCompanyId[companyId] ?? defaultCompanyRole;
}

function checkIfUserIsPrincipalInSomeCompany(companies: Company[], roleByCompanyId: Record<number, CompanyRole>) {
    return companies.some((c) => myCompanyRoleByCompanyId(roleByCompanyId, c.id)?.principal === true);
}

function checkPrivileges(
    p: PrivilegeConditions,
    myCompanyRole: CompanyRole,
    premium: boolean,
    companies: Company[],
    roleByCompanyId: Record<number, CompanyRole>,
): Result {
    const includesRole = p.roles.includes(myCompanyRole.role);
    if (!includesRole) {
        let missingRole = "";
        if (p.roles.includes("manager")) {
            missingRole = "manager";
        } else if (p.roles.includes("admin")) {
            missingRole = "admin";
        }
        return { hasPrivilege: false, missingRequirement: missingRole };
    }

    if (p.premiumNeeded && !premium) {
        return { hasPrivilege: false, missingRequirement: "premium" };
    }
    if (p.principalNeeded && !myCompanyRole.principal) {
        return { hasPrivilege: false, missingRequirement: "principal" };
    }
    if (p.isPrincipalInSomeCompany && !checkIfUserIsPrincipalInSomeCompany(companies, roleByCompanyId)) {
        return { hasPrivilege: false, missingRequirement: "principal" };
    }
    return { hasPrivilege: true };
}

export function useHasPrivileges(privilegeCase: Privilege, companyId?: number): Result {
    const t = useTranslation().t;
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin);
    const companies = useSelector((state: RootState): Company[] => state.hydrolink.companies.companies);
    const roleByCompanyId = useSelector<RootState, Record<number, CompanyRole>>(
        (state) => state.hydrolink.auth.roleByCompanyId,
    );
    const myCompanyRole = myCompanyRoleByCompanyId(roleByCompanyId, companyId);

    const premium: boolean = companies.find((c) => c.id === companyId)?.premium ?? false;

    if (isAdmin) {
        myCompanyRole.role = "admin";
    }

    const p = privilegeStore[privilegeCase];

    const result: Result = checkPrivileges(p, myCompanyRole, premium, companies, roleByCompanyId);
    return {
        hasPrivilege: result.hasPrivilege,
        missingRequirement:
            result.missingRequirement && t(`errors.${result.missingRequirement}`) + " " + t(`errors.required`),
    };
}
