import { createAction } from "@reduxjs/toolkit";
import {
    FetchDPAContractsSuccessPayload,
    SetSignLinkRequestSentByContractIdPayload,
    SignLinkRequestPayload,
    SignLinkRequestSuccessPayload,
} from "./dpa-contracts-types";

export const FetchDPAContractsAction = createAction<void>("FETCH_DPA_CONTRACTS");
export const FetchDPAContractsSuccessAction =
    createAction<FetchDPAContractsSuccessPayload>("FETCH_DPA_CONTRACTS_SUCCESS");
export const FetchDPAContractsFailureAction = createAction<void>("FETCH_DPA_CONTRACTS_FAILURE");

export const SendInviteeSignLinkAction = createAction<SignLinkRequestPayload>("SEND_INVITEE_SIGN_LINK");
export const SendInviteeSignLinkSuccessAction = createAction<SignLinkRequestSuccessPayload>(
    "SEND_INVITEE_SIGN_LINK_SUCCESS",
);
export const SendInviteeSignLinkFailureAction = createAction<void>("SEND_INVITEE_SIGN_LINK_FAILURE");
export const SetInviteeSignLinkSentByContractIdAction = createAction<SetSignLinkRequestSentByContractIdPayload>(
    "SET_INVITEE_SIGN_LINK_SENT_BY_CONTRACT_ID",
);
