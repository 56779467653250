import { Refresh } from "@mui/icons-material";
import { Alert, AlertTitle, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../interfaces/RootState";
import { FetchUserInformationAction } from "../../../redux/auth/auth-actions";
import { spacings } from "../../../theme";
import Centered from "../../Centered";

export default function DpaNotification(): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const hasPendingDpaContracts = useSelector((state: RootState) => state.hydrolink.auth.hasPendingDpaContracts);

    const checkForSignatures = () => {
        dispatch(FetchUserInformationAction());
    };

    if (hasPendingDpaContracts === true) {
        return (
            <Centered style={{ marginBottom: spacings.standardSpacing }}>
                <Alert
                    severity="warning"
                    style={{ width: "640px" }}
                    action={
                        <Tooltip title={t("dpa.checkForSignatures") as string}>
                            <IconButton onClick={checkForSignatures}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    }
                >
                    <AlertTitle>
                        <strong>{t("dpa.pendingContractsTitle")}</strong>
                    </AlertTitle>
                    <p style={{ whiteSpace: "break-spaces" }}>
                        {t("dpa.attention")}
                        {/* TODO: Different text when company and KOKA */}
                    </p>
                    <p style={{ whiteSpace: "break-spaces" }}>
                        {t("dpa.pendingContractsDescriptionManagerAndKOKA")}{" "}
                        {/* TODO: Different text when company and KOKA */}
                    </p>
                    <p style={{ whiteSpace: "break-spaces" }}>
                        {t("dpa.pendingContractsDescriptionLink")} {/* TODO: Add link*/}
                    </p>
                </Alert>
            </Centered>
        );
    }

    return <></>;
}
